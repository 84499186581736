body {
  &.projects-page {
    .main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }
    .featured-card {
      transform: scale(1.5);
    }
    .featured-card,
    .featured-card .card__image {
      height: 60vh;
      @media screen and (min-width: $tablet) {
        min-height: 90vh;
      }
    }
    .column {
      padding: 0.5rem;
      &.is-full {
        .card,
        .card__image {
          height: 60vh;
          @media screen and (min-width: $tablet) {
            min-height: 90vh;
          }
        }
      }
      &.is-half {
        .card,
        .card__image {
          height: 507.5px;
        }
      }
    }
    .card {
      cursor: pointer;
      position: relative;
      visibility: hidden;
      opacity: 0;
      &:hover {
        .overlay {
          opacity: 0.6;
        }
      }
      &__image-holder {
        overflow: hidden;
      }
      .overlay {
        transition: opacity 1.5s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
      }
      &__info-wrapper {
        padding: 0 1.5rem;
        width: 100%;
        position: absolute;
        bottom: 90px;
        display: flex;
        align-items: center;
        @media screen and (min-width: $tablet) {
        }
      }
      .card__new {
        position: absolute;
        right: 2rem;
        width: auto;
        height: 35px;
        @media screen and (min-width: $tablet) {
          height: 50px;
        }
        z-index: 5;
        &.top {
          top: 2rem;
        }
      }
      &__info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &__info-awards {
        margin-bottom: 2rem;
        .award {
          &:not(:last-child) {
            margin-right: 1.3rem;
          }
        }
      }
      &__info-client {
        @include interpolate(font-size, $mobile, $fullhd, 30px, 40px);
        font-weight: 600;
        color: #f8ffcd;
        line-height: 1;
        position: relative;
        left: -3px;
      }
      &__info-name {
        color: #f8ffcd;
        font-size: 12.5px;
        text-transform: uppercase;
      }
    }
  }
}
