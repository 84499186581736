body {
  &.about-page {
    background: linear-gradient(
      180deg,
      rgba(251, 255, 230, 1) 35%,
      rgba(153, 231, 160, 1) 80%,
      rgba(152, 231, 160, 1) 100%
    );

    .main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }
    .hero {
      &-body {
        position: relative;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .section--values {
      .value {
        position: relative;
        padding: 2rem 1.5rem;
        &__index {
          position: absolute;
          left: 0;
          top: 0;
          color: $chartreuse;
          font-weight: 700;
          letter-spacing: -0.02em;
          line-height: 1;
          @include interpolate(font-size, $mobile, $fullhd, 75px, 97.08px);
        }
        &__content {
          max-width: 100%;
          @media screen and (min-width: $tablet) {
            max-width: 90%;
          }
          @media screen and (min-width: $widescreen) {
            max-width: 80%;
          }
        }
        &__title,
        &__description p {
          position: relative;
          z-index: 3;
          color: $blue-2;
          @include interpolate(font-size, $mobile, $fullhd, 16.665px, 20px);
        }
        &__title {
          font-weight: 600;
        }
      }
    }
    .section--dei {
      .section-subtitle {
        margin-top: 1.1rem;
      }
    }
    .section--dei {
      position: relative;
      @media screen and (min-width: $tablet) {
        padding-top: 10.25em;
      }
      h3 {
        letter-spacing: 0.004em;
        text-align: center;
        color: $blue;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        span {
          position: relative;
          z-index: 2;
        }
        svg {
          z-index: 1;
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          height: 19.5px;
          width: 185px;
        }
      }
      .columns {
        padding-top: 3rem;
      }

      small {
        font-size: 11.9px;
        color: $blue;
        font-weight: 300;
      }
    }

    .dei-gender {
      padding-bottom: 6rem;
      position: relative;

      .graph {
        transform: skewX(-27deg);
        height: 34.5px;
      }
      &__grid {
        position: relative;
        display: flex;
        padding: 3rem 1rem;
        @media screen and (min-width: $tablet) {
          padding: 3rem 0;
        }
        cursor: pointer;
      }
      .label {
        position: absolute;
        bottom: -20px;
        left: 0;
      }

      .female {
        h2,
        .label {
          color: $blue;
        }
        .graph {
          background-color: $blue;
        }
        &:hover {
          h2 {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .male {
        h2,
        .label {
          color: $blue-2;
        }
        .graph {
          background-color: $blue-2;
        }
        &:hover {
          h2 {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .non-binary {
        h2,
        .label {
          color: $green;
          right: 0px;
          @media screen and (min-width: $tablet) {
            right: 0;
          }
        }
        h2 {
          opacity: 0;
          visibility: hidden;
          transform: scale(0.8);
          width: 27%;
          @media screen and (max-width: 414px) {
            width: 27%;
          }
          @media screen and (min-width: $tablet) {
            width: 20%;
          }
        }
        .graph {
          background-color: $green;
        }
        &:hover {
          h2 {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      h2 {
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        transform-origin: 50% 50%;
        bottom: 0;
        width: 100%;
        line-height: 1;
        @include interpolate(font-size, $mobile, $fullhd, 30px, 50px);
        font-weight: 700;
        .label {
          margin: 0;
          @include interpolate(font-size, $mobile, $fullhd, 12px, 16.665px);
          font-weight: 500;
        }
        .percent {
          @include interpolate(font-size, $mobile, $fullhd, 20px, 29.5px);
        }
      }
    }

    .dei-race {
      padding-bottom: 3.4rem;
      .column {
        position: relative;
        flex: 1;
        @media screen and (min-width: $tablet) {
          flex: none;
        }
        &:first-child {
          .label {
            @media screen and (min-width: $tablet) {
              right: -42px;
            }
          }
        }
      }
      h2 {
        position: relative;
        font-weight: bold;
        line-height: 1;
        @include interpolate(font-size, $mobile, $fullhd, 60px, 81.485px);
        color: hsla(212, 100%, 63%, 1);
        letter-spacing: -0.02em;
        width: 138.5px;
        @media screen and (min-width: $tablet) {
          width: fit-content;
        }
        .percent {
          @include interpolate(font-size, $mobile, $fullhd, 30px, 47.535px);
        }
      }
      .label {
        position: absolute;
        top: 0;
        @media screen and (min-width: $tablet) {
          top: 13px;
        }
        margin: 0;
        right: 0;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: hsla(212, 100%, 63%, 1);
        font-weight: 500;
        font-size: 12.6px;
      }
      .graph {
        transform: skewX(-27deg);
        width: 138.5px;
        height: 12px;
        background-color: hsla(233, 100%, 86%, 1);
      }
      .bar {
        position: absolute;
        height: 12px;
        background-color: hsla(212, 100%, 63%, 1);
      }
    }

    .section--partnerships {
      @media screen and (min-width: $tablet) {
        padding-top: 10.25em;
      }
      .partnerships {
        .partner {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 70%;
            @media screen and (min-width: $tablet) {
              width: 70%;
            }
          }
        }
      }
    }

    .section--people {
      padding-bottom: 0.9rem;
      position: relative;
      z-index: 5;
      @media screen and (min-width: $tablet) {
        padding-bottom: 0.7rem;
        padding-top: 10.25em;
        padding-left: 0;
        padding-right: 0;
        .container {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .section-headlines {
        margin-bottom: 124px;
      }
      .people-shape {
        pointer-events: none;
        position: absolute;
        height: 100%;
        top: -15%;
        left: 0;
        min-height: stretch;
        @media screen and (min-width: $tablet) {
          top: -16%;
        }
      }
    }

    .employee {
      position: relative;
      z-index: 5;
      margin-bottom: 2rem;
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
      @media screen and (min-width: $tablet) {
        margin-bottom: 5rem;
        &:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
      }
      &__image {
        position: relative;
        top: -17px;
        @media screen and (min-width: $tablet) {
          top: -58px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      &__overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 0 1rem;
        opacity: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__overlay-background {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.8;
      }
      &__quote {
        position: relative;
        z-index: 9;
      }
      &__content {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 23%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 16px;
        @media screen and (min-width: $tablet) {
          padding: 0 46px;
        }
      }
      svg {
        position: absolute;
        width: 100%;
        bottom: 0px;
      }
      &__title,
      &__name {
        position: relative;
        z-index: 2;
        @include interpolate(font-size, $mobile, $fullhd, 14px, 18px);
        color: hsla(210, 100%, 50%, 1);
      }
      &__name {
        font-weight: bold;
      }
    }
    .employee {
      &__overlay {
        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
          @include interpolate(font-size, $mobile, $fullhd, 12px, 15px);
        }
      }
    }
    &.safari,
    &.is-mobile {
      .section--people {
        overflow: hidden;
      }
    }
  }
}
