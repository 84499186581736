:root {
  --cl-size: 100%;
  --cl-gap: calc(var(--cl-size) / 13);
  --cl-duration: 20s;
  --cl-scroll-start: 0;
  --cl-scroll-end: calc(-100% - var(--cl-gap));
  //
  --a-size: clamp(16rem, 1rem + 15vmin, 19rem);
  --a-gap: calc(var(--a-size) / 13);
  --a-duration: 20s;
  --a-scroll-start: 0;
  --a-scroll-end: calc(-100% - var(--a-gap));
}
.offering-item__body-copy.content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.logo-fin {
  max-width: 20% !important;
  @media screen and (min-width: $tablet) {
    max-width: 10% !important;
  }
}
body {
  &.capabilities-page {
    background: linear-gradient(
      180deg,
      rgba(217, 244, 142, 1) 20%,
      rgba(212, 229, 249, 1) 40%,
      rgba(61, 77, 235, 1) 100%
    );
    .main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }

    .section--intro {
      position: relative;
      display: flex;
      justify-content: center;
      height: 100vh;
      @media screen and (min-width: $tablet) {
        height: 90vh;
      }
      img {
        height: 496px;
        margin: 0 auto;
        width: auto;
        object-fit: contain;
      }
    }
    .intro-state-one {
      display: flex;
      justify-content: center;
      align-items: center;
      clip-path: inset(0% 0% 100% 0%);
      top: 50%;
      transform: translateY(-50%);
    }
    .intro-state-two {
      clip-path: inset(0% 0% 100% 0%);
    }
    .intro-state-one,
    .intro-state-two {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    .principles__headline {
      color: $blue;
      text-align: center;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include interpolate(font-size, $mobile, $fullhd, 30px, 50px);
      }
    }
    .principles {
      h3 {
        max-width: 100%;
        margin-bottom: 2rem;
        @media screen and (min-width: $tablet) {
          max-width: 60%;
          margin: 0 auto 2rem auto;
        }
      }
    }
    .principle {
      position: relative;
      color: $blue;
      &__list {
        padding-top: 3rem;
      }
      &__index {
        font-weight: bold;
        @include interpolate(font-size, $mobile, $fullhd, 50px, 101.295px);
        color: #daff02;
        position: absolute;
        top: 0;
        left: 30px;
        @media screen and (min-width: $tablet) {
          left: 20px;
        }
      }
      &__copy {
        position: relative;
        z-index: 2;
        padding: 2rem;
        max-width: 100%;
        @media screen and (min-width: $tablet) {
          max-width: 82%;
          margin: 0 auto;
        }
      }
    }

    .capabilities-copy {
      padding-bottom: 99px;
      color: $blue-2;
      max-width: 100%;
      margin: 0 auto;
      @media screen and (min-width: $tablet) {
        max-width: 55%;
      }
      h1,
      h2,
      h3,
      p {
        letter-spacing: -0.02em;
        @include interpolate(font-size, $mobile, $fullhd, 20px, 25px);
      }
    }

    .section--partners {
      padding-left: 0;
      padding-right: 0;
    }
    .section-headlines {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .cl-marquee-content {
      display: flex;
      width: 100vw;
      @media screen and (min-width: $tablet) {
        width: 50vw;
      }
      &.is-visible {
        animation: MobileScrolling 25s linear infinite;
        @media screen and (min-width: $tablet) {
          animation: scrolling 25s linear infinite;
        }
      }
    }

    .cl-marquee-item {
      flex: 0 0 100vw;
      @media screen and (min-width: $tablet) {
        flex: 0 0 50vw;
      }
      margin: 0;
      overflow: visible;
      img {
        display: block;
        width: 100%;
      }
    }

    @keyframes scrolling {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translatex(-100vw);
      }
    }
    @keyframes MobileScrolling {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translatex(-204vw);
      }
    }
    @media (prefers-reduced-motion: reduce) {
      .cl-marquee-content {
        animation-play-state: paused;
      }
    }

    .section--offerings {
      .section-headlines {
        h3,
        p {
          color: #fff;
        }
      }
    }

    .section--awards {
      h3,
      p {
        color: #fff;
      }
    }
    .award-marquee {
      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--a-gap);
        margin: auto;
        max-width: 100vw;
      }
    }

    .marquee {
      display: flex;
      overflow: hidden;
      user-select: none;
      gap: var(--a-gap);

      &__group {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: var(--a-gap);
        min-width: 100%;
        animation: scroll-x var(--a-duration) linear infinite;
      }
      &__item {
        display: grid;
        place-items: center;
        max-width: 50%;
        margin: 0 auto;
      }
      &__logo {
        width: var(--a-size);
        padding: calc(var(--a-size) / 15);
        @media screen and (min-width: $tablet) {
          padding: calc(var(--a-size) / 5);
        }
      }
    }

    @media (prefers-reduced-motion: reduce) {
      .marquee__group {
        animation-play-state: paused;
      }
    }

    @keyframes scroll-x {
      from {
        transform: translateX(var(--a-scroll-start));
      }
      to {
        transform: translateX(var(--a-scroll-end));
      }
    }
    //
  }
  .p-carousel-next,
  .p-carousel-prev {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    .p-icon {
      height: 25px;
      width: 25px;
      fill: #fff;
    }
  }
  .p-carousel-next {
    right: 20px;
  }
  .p-carousel-prev {
    left: 20px;
  }
  .p-carousel-indicator {
    &:first-child,
    &:nth-child(2) {
      margin-right: 1rem;
    }
    button {
      width: 50px;
      height: 5px;
      border: none;
      cursor: pointer;
    }
    opacity: 0.5;
    background-color: #eee;
    button {
      opacity: 0.5;
      background-color: #eee;
    }
    &.p-highlight {
      opacity: 1;
      background-color: #fff;
      button {
        opacity: 1;
        background-color: #fff;
      }
    }
  }
}
