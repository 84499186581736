body {
  &.home-page {
    background: linear-gradient(
      180deg,
      rgba(140, 201, 254, 1) 0%,
      rgba(197, 231, 254, 1) 70%,
      rgba(166, 215, 254, 1) 100%
    );

    .headline {
      .home-page__headline {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (min-width: $tablet) {
          align-items: center;
        }
      }
      p {
        font-weight: 300;
        color: $blue;
        @include interpolate(font-size, $mobile, $fullhd, 40px, 80px);
        @media screen and (max-width: 414px) {
          line-height: 1.3;
          text-align: left;
        }
      }
    }
    .hero {
      display: flex;
      justify-content: center;
      flex-direction: row;
      position: relative;

      .hero-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        @media screen and (min-width: $tablet) {
          // padding-bottom: 3rem;
        }
      }

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 100% 0 0 0;
        @media screen and (min-width: $tablet) {
          padding: 56.25% 0 0 0;
        }
        background-color: hsla(0, 0%, 0%, 0.2);
      }
    }
    .iframe-wrapper {
      padding: 100% 0 0 0;
      @media screen and (min-width: $tablet) {
        padding: 56.25% 0 0 0;
      }
      clip-path: inset(0% 0% 100% 0);
      position: relative;
      iframe,
      video {
        visibility: hidden;
      }
      video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 176vw;
        @media screen and (min-width: $tablet) {
          left: 0;
          transform: translateX(0);
          width: 100%;
        }
        object-fit: cover;
        height: 100%;
      }
      picture,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
    .section--intro {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5rem 1.5rem;
      @media screen and (min-width: $tablet) {
        padding: 9rem 3rem;
      }
    }

    .intro {
      position: relative;
      color: $blue;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 4rem;
        @media screen and (min-width: $tablet) {
          margin-bottom: 6rem;
        }
      }
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      img {
        line-height: 1.2;
        font-size: 5.7vmin;
        @media screen and (min-width: $tablet) {
          font-size: 7vmin;
        }
      }
      img {
        pointer-events: none;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        @media screen and (min-width: $tablet) {
          max-width: 65%;
          height: 100%;
        }
      }
    }
    .card-wrapper {
      padding: 0.5rem 0rem;
    }
    .card,
    .card__image {
      height: 60vh;
      @media screen and (min-width: $tablet) {
        min-height: 90vh;
      }
    }
    .card {
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      position: relative;
      &:hover {
        .overlay {
          opacity: 0.7;
        }
      }
      &__image-holder {
        overflow: hidden;
      }
      .overlay {
        transition: opacity 1.5s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
      }
      &__info-wrapper {
        height: 100%;
        width: 100%;
        padding: 0.5rem 1.3rem;
        @media screen and (min-width: $tablet) {
          padding: 0.5rem 3rem;
        }
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .card__new {
        position: absolute;
        right: 2rem;
        top: 2rem;
        width: auto;
        height: 50px;
        z-index: 5;
      }
      &__info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &__info-awards {
        margin-bottom: 2rem;
        .award {
          &:not(:last-child) {
            margin-right: 1.3rem;
          }
        }
      }
      &__info-client {
        @include interpolate(font-size, $mobile, $fullhd, 50px, 79.165px);
        font-weight: 600;
        color: #f8ffcd;
        line-height: 1;
        position: relative;
        left: -3px;
      }
      &__info-name {
        color: #f8ffcd;
        font-size: 16.665px;
        text-transform: uppercase;
      }
    }

    .section--news {
      h3,
      h2 {
        text-align: center;
        font-weight: 300;
        color: $blue;
        line-height: 1;
      }
      h3 {
        @include interpolate(font-size, $mobile, $fullhd, 17px, 20.835px);
        margin-bottom: 17.5px;
      }
      h2 {
        @include interpolate(font-size, $mobile, $fullhd, 37px, 41.665px);
        margin-bottom: 60.5px;
      }
    }
    .news-card {
      &__image-holder {
        overflow: hidden;
        margin-bottom: 26px;
        position: relative;
      }
      &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        height: 230px;
        width: 100%;
        @media screen and (min-width: $tablet) {
          height: 390px;
          width: 100%;
        }
      }
      &__overlay {
        transition: opacity 1.5s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__info-title,
      &__info-description {
        @include interpolate(font-size, $mobile, $fullhd, 17px, 25px);
        line-height: 1.3;
      }
      &__info-title {
        font-weight: 600;
      }
      &__info-description {
        max-width: 90%;
        @media screen and (min-width: $desktop) {
          max-width: 70%;
        }
        @media screen and (min-width: $widescreen) {
          max-width: 53%;
        }
      }
      &:hover {
        .news-card__overlay {
          opacity: 0.7;
        }
      }
    }
  }
}
