body {
  &.careers-page {
    background: linear-gradient(
      180deg,
      rgba(254, 235, 233, 1) 40%,
      rgba(218, 174, 244, 1) 60%,
      rgba(206, 153, 248, 1) 100%
    );
    .main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }
    .section-intro {
      max-width: 64%;
    }
    chevronrighticon,
    chevrondownicon {
      display: none;
    }
    p-accordiontab {
      display: block;
    }
    .perk__content {
      b {
        display: block;
      }
      ul {
        margin: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
    .p-accordion-content {
      ul {
        margin-left: 0;
        list-style: none;
      }
    }
    .culture-copy {
      text-align: center;
      i {
        display: block;
      }
    }
    .open-role__description {
      font-size: 16.665px;
      color: $blue;
    }
  }
}
