body {
  &.contact-page {
    main {
      padding-top: 0;
    }
    .footer {
      margin-top: 0;
    }
    .hero-body__headline {
      padding-bottom: 3rem;
      color: $blue;
      @include interpolate(font-size, $mobile, $fullhd, 45px, 55px);
    }
  }
}
