//
// Base Colors
//
$white: hsla(0, 0%, 94%, 1);
$black: hsla(0, 0%, 0%, 1);

//
// Blues
//
$blue: hsla(231, 82%, 36%, 1);
$font-blue: hsla(231, 82%, 36%, 1);

//
// Yellows
//
$chartreuse: hsla(69, 100%, 50%, 1);
$light-yellow: #faffe2;
$background-light-yellow: $light-yellow;

$green: hsla(124, 94%, 34%, 1);
$blue-2: hsla(210, 100%, 50%, 1);
$pink: hsla(323, 73%, 51%, 1);

//
// Components
//
$footer-background: hsla(40, 7%, 9%, 1);

//
// Fonts
//
$font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
$body-family: $font-family;
$body-color: $black;
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semi-bold-font-weight: 600;
$bold-font-weight: bold;

//
// Responsiveness
//
$body-min-width: 320px;
$mobile: 768px; // $mobile max-width

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 22px !default;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;

// 960px container + 4rem
$desktop: 960px + 2 * $gap !default;

// 1152px container + 4rem
$widescreen: 1152px + 2 * $gap !default;
$widescreen-enabled: true !default;

// 1344px container + 4rem
$fullhd: 1344px + 2 * $gap !default;

//
// Bulma Overrides
//
$navbar-background-color: transparent;
$navbar-item-active-background-color: $blue;
$navbar-item-hover-background-color: transparent;
$strong-color: $blue;
$content-heading-color: $black;
$footer-background-color: $footer-background;
$footer-color: $white;
$section-padding-medium: 9rem 3rem;
$section-padding-large: 18rem 3rem;
$hr-background-color: $blue;
$hr-height: 2px;
$hr-margin: 1rem 0;
$strong-color: $blue;
$content-blockquote-background-color: transparent;
$content-blockquote-border-left: 0;
$content-blockquote-padding: 1.25em 1.5em;
$footer-padding: 5.688rem 1.5rem 5.813rem;
// $footer-padding: 5.688rem 7.188rem 5.813rem;
