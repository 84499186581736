body {
  &.news-page,
  &.news-item-page,
  &.news-category-page {
    background: linear-gradient(
      180deg,
      rgba(236, 184, 230, 1) 0%,
      rgba(184, 198, 247, 1) 60%,
      rgba(160, 204, 255, 1) 100%
    );
  }

  &.news-item-page {
    .content {
      .video-player {
        margin-bottom: 2em;
      }
      h1 {
        text-align: left;
      }
      .image {
        margin-left: 0;
        margin-right: 0;
        max-height: 560px;
        img {
          max-height: 560px;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%;
        }
      }
    }
    .image-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 2em 0;
      &__img {
        flex: 100%;
        max-height: 460px;
        &:not(:last-child) {
          padding-bottom: 1em;
        }
        @media screen and (min-width: $tablet) {
          flex: 1;
          &:not(:last-child) {
            padding-bottom: 0em;
          }
          &:not(:first-child) {
            padding-left: 0.5em;
          }
          &:last-child {
            padding-right: 0em;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
  }
}
