body {
  &.work-item-page {
    .main {
      padding-top: 0;
    }
    background: linear-gradient(
      180deg,
      rgba(205, 231, 254, 1) 0%,
      rgba(147, 200, 255, 1) 54%,
      rgba(104, 177, 255, 1) 100%
    );
    .footer {
      margin-top: 0;
    }
    .hero {
      display: flex;
      justify-content: center;
      flex-direction: row;
      position: relative;
      background-size: cover;
      background-position: 50% 50%;
      .hero-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .hero__content {
        height: 100vh;
        position: relative;
        iframe {
          visibility: hidden;
        }

        picture,
        img {
          position: absolute;
          top: 0;
          left: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
        @media screen and (min-width: $tablet) {
          // padding: 56.25% 0 0 0;
        }
      }
    }
    .project {
      &__intro {
        color: $blue;
        font-size: 16.665px;
      }
      &__client {
        color: $blue;
        @include interpolate(font-size, $mobile, $fullhd, 20px, 32px);
        font-weight: 700;
      }
      &__name {
        @include interpolate(font-size, $mobile, $fullhd, 20px, 32px);
        color: $blue-2;
      }
    }
    .section--intro {
      padding-bottom: 3rem;
      @media screen and (max-width: 414px) {
        padding-bottom: 0;
      }
      .columns {
        margin: 0;
      }
    }
    .section--body-copy {
      // padding-top: 0;
      padding-bottom: 3rem;
      .columns {
        margin: 0;
      }
      .left {
        @media screen and (min-width: $tablet) {
          padding-right: 4.5rem;
          padding-left: 0;
          margin-left: 0;
        }
        color: $blue;
      }
      .right {
        @media screen and (min-width: $tablet) {
          padding-left: 4.5rem;
          padding-right: 0;
          margin-left: 0;
        }
        color: $blue;
      }
    }

    .section--spot {
      .project__spot {
        &-name {
          @include interpolate(font-size, $mobile, $fullhd, 20px, 32px);
          color: $blue;
          font-weight: 500;
          margin-bottom: 2rem;
          text-align: center;
        }
        &-player {
          padding: 56.25% 0 0 0;
          position: relative;
          margin-bottom: 2rem;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        &-body-copy {
          color: $blue;
        }
      }
    }

    .section--results {
      min-height: 600px;
      padding-left: 0;
      padding-right: 0;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      .shape {
        position: absolute;
        top: 0;
        height: 305px;
        width: 100vw;
        object-fit: cover;
      }
    }
    .result__content {
      max-width: 60%;
      margin: 0 auto;
      min-height: 305px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media screen and (min-width: $tablet) {
        max-width: 45%;
      }
      h3 {
        line-height: 1;
        @include interpolate(font-size, $mobile, $fullhd, 50px, 110.535px);
        font-weight: 600;
        span {
          @include interpolate(font-size, $mobile, $fullhd, 40.985px, 60.985px);
        }
      }
    }
    .grid-item {
      position: relative;
      .result__content {
        color: $blue-2;
        position: relative;
        z-index: 3;
      }
    }
    .grid-item-1 {
      grid-area: 2 / 1 / 3 / 2;
      .shape {
        left: 0;
      }
      .result__content {
        left: 5%;
        @media screen and (min-width: $tablet) {
          left: 0%;
        }
      }
    }
    .grid-item-2 {
      grid-area: 1 / 2 / 2 / 3;
      .shape {
        right: 0;
      }
    }

    .section--media {
      .column {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        img {
          object-fit: contain;
        }
      }
    }

    .section--social {
      .column {
        padding: 2rem;
        display: flex;
        justify-content: center;
      }
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $blue;
      }
    }

    .next-project {
      padding-bottom: 6rem;
      @media screen and (min-width: $tablet) {
        padding-bottom: 12rem;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__title {
        font-size: 16.665px;
        color: $blue;
        margin-bottom: 33px;
      }
      &__client {
        @include interpolate(font-size, $mobile, $fullhd, 39px, 79.165px);
        letter-spacing: -0.02em;
        line-height: 1;
        color: $blue;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
